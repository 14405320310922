import * as React from "react";
import useMedia from 'use-media';
import Header from './Header';

import { makeStyles } from '@material-ui/core/styles';

const useLayoutStyles = makeStyles(theme=> ({
  toolbar: theme.mixins.toolbar
}));

const Layout = ({ location, title, children, categories }) => {
  const classes = useLayoutStyles();
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;
  const isMobile = useMedia({maxWidth: '42rem'});
  //<Header title={title} isMobile={isMobile} categories={categories}/>      

  return (
    <div>
      <Header title={title} isMobile={isMobile} categories={categories}/>
      <div className={classes.toolbar} />
      <div className="global-wrapper" data-is-root-path={isRootPath}>
       <main>{children}</main>
       <footer>
        　© {new Date().getFullYear()}, Dealing with Ambiguity
       </footer>
      </div>
    </div>  
  );
}

export default Layout;
